import React, {useMemo, useState} from "react";
import {Box, Breadcrumbs, Stack, Tabs, Typography} from "@mui/material";
import {EditOutlined, HeartBrokenOutlined, ReplyOutlined} from "@mui/icons-material";
import {Page} from "../app/Page";
import {FormsManagedTab} from "../forms-list/FormsManagedCardList";
import {useUser} from "../user-auth/UserProvider";
import {UserRequiredRedirect} from "../user-auth/UserRequiredRedirect";
import AppConsts from "../app/AppConsts";
import {t} from "i18next";
import Tab from "@mui/material/Tab";
import {FormsCardList} from "../forms-list/FormsCardList";
import {PreHospitalWorkflowsTab} from "../pre-hospital-workflow/PreHospitalWorkflowsList";

export function HomePageMiddleware() {
    const user = useUser();
    if (!user.logged) {
        return <UserRequiredRedirect />;
    }
    return <HomePage />;
}

function HomePage() {
    const user = useUser();
    
    const avaibleTabs = useMemo(() => {
        const tabs = [];
        if (user.data.canManagePreHospitalWorkflows) {
            tabs.push('preHospitalWorkflows');
        }
        tabs.push('userForms');
        if (user.data.canManageForms) {
            tabs.push('dashboardForms');
        }
        return tabs;
    }, [user.data.canManageForms, user.data.canManagePreHospitalWorkflows]);

    const [currentTab, setCurrentTab] = useState(avaibleTabs.length ? avaibleTabs[0] : null);

    return (
        <Page>
            <Breadcrumbs sx={{mb: 4}}>
                <Typography color="text.primary">
                    {AppConsts.NAME}
                </Typography>
            </Breadcrumbs>
            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 4}}>
                <Tabs value={currentTab} centered>
                    {avaibleTabs.includes('preHospitalWorkflows') &&
                        <Tab
                            onClick={() => setCurrentTab('preHospitalWorkflows')}
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <HeartBrokenOutlined fontSize="small"/>
                                    <Typography>{t('forms_list.pre_hospital_workflows')}</Typography>
                                </Stack>}
                            value={'preHospitalWorkflows'} />}
                    {avaibleTabs.includes('userForms') &&
                        <Tab
                            onClick={() => setCurrentTab('userForms')}
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <ReplyOutlined fontSize="small"/>
                                    <Typography>{t('forms_list.fill_forms')}</Typography>
                                </Stack>}
                            value={'userForms'} />}
                    {avaibleTabs.includes('dashboardForms') &&
                        <Tab
                            onClick={() => setCurrentTab('dashboardForms')}
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <EditOutlined fontSize="small"/>
                                    <Typography>{t('forms_list.managed_forms')}</Typography>
                                </Stack>}
                            value={'dashboardForms'} />}
                </Tabs>
            </Box>
            {currentTab === 'preHospitalWorkflows' && <PreHospitalWorkflowsTab />}
            {currentTab === 'dashboardForms' && <FormsManagedTab />}
            {currentTab === 'userForms' && <FormsCardList />}
        </Page>
    );
}