import {
    Alert,
    Box,
    Card,
    CardActionArea, CardActions, CardContent,
    CircularProgress,
    Divider,
    Grid,
    Grow, IconButton, ListItemIcon, Menu, MenuItem, Pagination,
    Paper, Stack,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {AddOutlined, DeleteOutlined, EditOutlined, MoreVertOutlined} from "@mui/icons-material";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useHistory} from "react-router";
import {PathGenerator} from "../app/PageRouting";
import {httpClient} from "../utils/httpClient";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import {useSnackbar} from "../providers/SnackbarProvider";
import {t} from "i18next";
import {scrollToElementByRef} from "../utils/scroll";
import {NavLink} from "react-router-dom";
import {useLocalizationProvider} from "../hooks/useLocalizationProvider";
import {useTranslation} from "react-i18next";

export function PreHospitalWorkflowsTab() {
    const [viewState, setViewState] = useState({code: "loading"});
    const [pageNumber, setPageNumber] = useState(1);
    const [workflows, setWorkflows] = useState(null);
    const history = useHistory();
    const {createSnackbar} = useSnackbar();
    const topRef = useRef(null);
    const [refreshCounter, setRefreshCounter] = useState(0);

    const handleNewClick = () => {
        httpClient.post(`${AppConsts.API_PATH}/pre-hospital-workflows`, {
            patient: {},
            contact: {},
            anamnesis: {},
            parameters: {}
        }).then(response => {
            if (response.data.workflow) {
                history.push(PathGenerator.preHospitalWorkflow(response.data.workflow.id));
            } else {
                createSnackbar({
                    message: response.data.errors[0].message,
                    autoHideDuration: 2000
                });
            }
        }).catch((e) => {
            logError(e);
            createSnackbar({
                message: t('common.sorry_something_went_wrong'),
                autoHideDuration: 2000
            });
        });
    };

    const handleReloadPage = () => {
        setRefreshCounter(counter => counter + 1);
    };

    useEffect(() => {
        const cancelTokenSource = httpClient.cancelToken();
        let isMounted = true;

        httpClient.get(`${AppConsts.API_PATH}/pre-hospital-workflows?pageNumber=${pageNumber}&pageSize=12`, {
            cancelToken: cancelTokenSource.token
        }).then(response => {
            if (!isMounted) {
                return;
            }
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                const data = response.data;
                setWorkflows({
                    pageNumber: data.pageable.pageNumber + 1,
                    totalPages: data.totalPages,
                    totalForms: data.totalElements,
                    list: data.content
                });
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            if (!isMounted) {
                return;
            }
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });

        return () => {
            isMounted = false;
            cancelTokenSource.cancel();
        }
    }, [pageNumber, refreshCounter]);

    if (viewState.code === "loading") {
        return <CircularProgress/>;
    }

    if (viewState.code === "loadingError") {
        return <Alert severity="error">{viewState.text}</Alert>;
    }

    if (!workflows.list.length) {
        return (<>
            <Paper variant="outlined" sx={{
                flexGrow: true,
                display: 'flex',
                alignItems: ' center',
                minHeight: "50vh",
                justifyContent: "center"
            }}>
                <Button variant="outlined" startIcon={<AddOutlined/>} onClick={handleNewClick}>
                    Nuovo Triage
                </Button>
            </Paper>
        </>);
    }

    return (
        <Box ref={topRef}>
            <Box sx={{mb: 4}}>
                <Button variant="contained" startIcon={<AddOutlined/>} onClick={handleNewClick}>
                    Nuovo Triage
                </Button>
            </Box>
            <Grid container spacing={4}>
                {workflows.list.map(m => (
                    <Grid item xs={12} sm={6} lg={4} key={m.id}>
                        <PreHospitalWorkflowListElem workflow={m} handleReloadPage={handleReloadPage} />
                    </Grid>
                ))}
            </Grid>
            {workflows.totalPages > 1 && <>
                <Divider sx={{mt: 5, mb: 2}}/>
                <Pagination
                    onChange={(e, page) => {
                        setPageNumber(page);
                        scrollToElementByRef(topRef);
                    }}
                    count={workflows.totalPages}/></>}
        </Box>
    );
}

function PreHospitalWorkflowListElem({workflow, handleReloadPage}) {
    const history = useHistory();
    const {createSnackbar} = useSnackbar();

    const [menuAnchor, setMenuAnchor] = useState(null);
    const menuOpen = Boolean(menuAnchor);
    const handleCloseMenu = useCallback(() => {
        setMenuAnchor(null);
    }, []);
    const handleClickMore = useCallback((event) => {
        setMenuAnchor(event.currentTarget);
    }, [setMenuAnchor]);

    const handleDelete = useCallback(() => {
        handleCloseMenu();
        if (window.confirm("Confermi di voler rimuovere il modulo?")) {
            httpClient.delete(`${AppConsts.API_PATH}/pre-hospital-workflows/${workflow.id}`).then(response => {
                if (response.data.id) {
                    handleReloadPage();
                } else {
                    createSnackbar({
                        message: response.data.errors[0].message,
                        autoHideDuration: 2000
                    });
                }
            }).catch(() => {
                createSnackbar({
                    message: t('common.sorry_something_went_wrong'),
                    autoHideDuration: 2000
                });
            });
        }
    }, [createSnackbar, handleCloseMenu, handleReloadPage, workflow.id]);

    const {localeBcp47} = useLocalizationProvider();

    const lastUpdateAtFormatted = useMemo(() => {
        return new Date(workflow.lastUpdateAt).toLocaleString(localeBcp47);
    }, [workflow.lastUpdateAt]);

    return (
        <Grow in={true}>
            <Card variant="outlined" sx={{width: 1}}>
                <CardActionArea onClick={() => history.push(PathGenerator.preHospitalWorkflow(workflow.id))}>
                    <CardContent>
                        <Typography gutterBottom variant="h2" noWrap>
                            {workflow.patient.name || 'Paziente senza nome'}
                        </Typography>
                        <Typography color="text.secondary" noWrap>
                            {lastUpdateAtFormatted}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{width: 1}}>
                        <Button component={NavLink} to={PathGenerator.preHospitalWorkflow(workflow.id)} size="small">
                            <EditOutlined sx={{fontSize: 14, mr: 1}}/>
                            Modifica
                        </Button>
                        <IconButton
                            aria-haspopup="true"
                            onClick={handleClickMore}>
                            <MoreVertOutlined/>
                        </IconButton>
                    </Stack>
                </CardActions>
                <Menu
                    anchorEl={menuAnchor}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                    PaperProps={{
                        style: {
                            minWidth: 220,
                        },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <MenuItem onClick={handleDelete} key="delete">
                        <ListItemIcon>
                            <DeleteOutlined fontSize="small"/>
                        </ListItemIcon>
                        {t('common.delete')}
                    </MenuItem>
                </Menu>
            </Card>
        </Grow>
    );
}