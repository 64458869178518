import {useTranslation} from "react-i18next";
import enUSLocale from "date-fns/locale/en-US";
import {enUS, itIT} from "@mui/x-date-pickers/locales";
import itLocale from "date-fns/locale/it";

export function useLocalizationProvider() {
    const {i18n} = useTranslation();
    let adapterLocale, localeText, localeBcp47;
    switch (i18n.resolvedLanguage) {
        case 'en':
            adapterLocale = enUSLocale;
            localeText = enUS.components.MuiLocalizationProvider.defaultProps.localeText;
            localeBcp47 = 'en-US';
            break;
        default:
        case 'it':
            adapterLocale = itLocale;
            localeText = itIT.components.MuiLocalizationProvider.defaultProps.localeText;
            localeBcp47 = 'it-IT';
            break;
    }
    return {adapterLocale, localeText, localeBcp47};
}